import React from 'react'

export default function WhatWhy() {
  return (
    <div className="what">
      <div className="row">
        <div className="medium-3 columns">
          <h2>What is weedy rice?</h2>
          <p>
            Weedy rice, also known as red rice, is a plant that belongs to the
            same genus and species as cultivated rice. In other words, it is a
            very close relative of cultivated rice. Because of this, the
            herbicides we use in rice do not kill it.
          </p>
        </div>
        <div className="medium-3 columns">
          <h2>Why is weedy rice a problem?</h2>
          <p>
            Weedy rice is one of the most damaging weeds of rice worldwide. It
            can affect yield and quality significantly. Reports from the
            southern US show that yields can be reduced by 60%.
          </p>
        </div>
        <div className="medium-3 columns">
          <h2>How extensive is the weedy rice problem in California?</h2>
          <p>
            California was practically free of weedy rice for the past 50 years but the weed has recently resurfaced. By 2021, over 3000 acres have been found infested.
          </p>
        </div>
        <div className="medium-3 columns">
          <h2>What should I do if I suspect I have weedy rice in my field?</h2>
          <p>
            Follow the identification guidelines in this handout to rule out
            weedy rice.
          </p>
        </div>
      </div>
    </div>
  )
}
