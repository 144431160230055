import React from 'react'
import Espino from '../../assets/img/espino.jpg'
import Miles from '../../assets/img/Leinfelder-Miles.jpg'
import Whitney from '../../assets/img/whitney.jpg'

export default function Contact() {
  return (
    <div className="contact">
      <div className="row">
        <div className="small-12 columns">
          <h2>Contact</h2>
        </div>

        <div className="medium-4 columns">
          <img src={Espino} alt="" width="116" height="116" />
          <strong>Luis Espino</strong>
          <p>
            UCCE Rice Advisor
            <br />
            Butte and Glenn
            <br />
            (530) 635-6234
          </p>
          <a href="mailto:laespino@ucanr.edu">laespino@ucanr.edu</a>
        </div>

        <div className="medium-4 columns">
          <img src={Miles} alt="" width="116" height="116" />
          <strong>Michelle Leinfelder-Miles </strong>
          <p>
            UCCE Farm Advisor
            <br />
            San Joaquin
            <br />
            (209) 953-6120
          </p>
          <a href="mailto:mmleinfeldermiles@ucanr.edu">
            mmleinfeldermiles@ucanr.edu
          </a>
        </div>

        <div className="medium-4 columns">
          <img src={Whitney} alt="" width="116" height="116" />
          <strong>Whitney Brim-DeForest</strong>
          <p>
            UCCE Rice Advisor
            <br />
            Sutter, Yuba, Placer,
            <br />
            Sacramento, Butte
            <br />
            (530) 822-7515
          </p>
          <a href="mailto:wbrimdeforest@ucanr.edu">wbrimdeforest@ucanr.edu</a>
        </div>
      </div>
    </div>
  )
}
