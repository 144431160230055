import React from 'react'
import axios from 'axios'

class PrevIssues extends React.Component {
  state = {
    data: null,
  }

  componentDidMount() {
    axios
      .get('https://campaign.constantcontact.com/archive?m=a07eds9bs230&l=3')
      .then(response => {
        this.setState({ data: response.data })
      })
  }

  render() {
    const { data } = this.state
    return (
      <div id="archiveList">
        <ul>
          {data &&
            data.map(listItem => (
              <li key={listItem.subject}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={listItem.campaign_url}
                >
                  {listItem.subject}
                </a>
              </li>
            ))}
        </ul>
      </div>
    )
  }
}

export default PrevIssues
