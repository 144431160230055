import React from 'react'
import ligule from '../../assets/img/ligule-auricle.jpg'
import watergrass from '../../assets/img/weedyrice-watergrass.jpg'
import suspicious from '../../assets/img/suspicious-plant.jpg'
import equipmentIcon from '../../assets/img/equipment-icon.png'
import plantingIcon from '../../assets/img/planting-icon.png'

export default function Prevention() {
  return (
    <div className="prevention">
      <section className="main">
        <div className="row">
          <div className="small-12 columns">
            <h2>Prevention and Management</h2>
            <p>
              Weedy rice (aka red rice) is a major problem in rice production in many parts of the world, and is found infesting over 3000 acres, as of 2021. Once weedy rice is established in a field, it is very difficult to eradicate since weedy rice plants cannot be controlled with rice herbicides. Furthermore, weedy rice seeds can survive for a long time in the soil, meaning that eradication measures become more difficult if plants are allowed to produce seed. Yield reductions in the southern US are as high as 60% in weedy rice infested fields. If weedy rice contaminates other rice during drying and storage, extra milling and sorting is required, which increases costs and may cause cracking and reduce milling yields.
            </p>

            <h3>Weedy Rice Identification Guidelines</h3>
          </div>
        </div>
        <div className="row collapse">
          <div className="medium-6 columns green-arrow-right">
            <span>
              <p>
                Once all watergrass herbicide applications have been made,
                inspect surviving watergrass-like plants to rule out weedy rice.
                Check the collar to see if there is a ligule and auricle.
              </p>
            </span>
          </div>
          <div className="medium-6 columns plant-pic">
            <img src={ligule} alt="" />
          </div>
        </div>

        <div className="row collapse">
          <div className="medium-6 columns plant-pic">
            <img src={watergrass} alt="" />
          </div>
          <div className="medium-6 columns green-arrow-left">
            <span>
              <p>
                If ligule and auricle are absent, then the plant is watergrass.
              </p>
              <p>
                If ligule and auricle are present, then the plant is rice or
                weedy rice.
              </p>
            </span>
          </div>
        </div>

        <div className="row collapse">
          <div className="medium-6 columns green-arrow-right">
            <span>
              <p>
                If a plant looks suspicious, contact a UCCE Farm Advisor to
                confirm the identification. If the identification is positive,
                follow the best management practices developed by UCCE.
              </p>
            </span>
          </div>
          <div className="medium-6 columns plant-pic">
            <img src={suspicious} alt="" />
          </div>
        </div>
      </section>

      <section className="guideline-drk">
        <div className="row">
          <div className="small-12 columns">
            <h3>Guidelines to prevent infestations</h3>
          </div>
          <div className="medium-6 columns">
            <div className="guideline-wrap">
              <div className="guideline-icon">
                <img src={equipmentIcon} alt="" />
              </div>
              <div className="guidelines-detail">
                <h4>Equipment</h4>
                <p>
                  All equipment coming into California from areas known to have
                  weedy rice infestations must be thoroughly cleaned and
                  inspected by the County Agricultural Commissioner.
                </p>
              </div>
            </div>
          </div>
          <div className="medium-6 columns">
            <div className="guideline-wrap">
              <div className="guideline-icon">
                <img src={plantingIcon} alt="" />
              </div>
              <div className="guidelines-detail">
                <h4>Planting</h4>
                <p>
                  Only use certified seed or seed produced in the Quality
                  Assurance Program.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="guideline-lgt">
        <div className="gutter-24 row">
          <div className="small-12 columns">
            <h3>Guidelines to manage weedy rice in your field</h3>
          </div>
        </div>
        <div className="gutter-24 row">
          <div className="medium-3 columns border-right">
            <h4>During the Season</h4>
            <ul>
              <li>
                Before heading, rogue weedy  rice plants from the field, or cut
                the plants at the base below  the water line. If plants have 
                headed,cut off the seed heads  into a bucket or bag to prevent 
                seed shattering.
              </li>
              <li>
                Bagged seed heads should be disposed of in a landfill. Do  not
                leave them by the side of the field.
              </li>
              <li>Keep water on the field with  no drainage until harvest.</li>
            </ul>
          </div>
          <div className="medium-3 columns border-right">
            <h4>At Harvest</h4>
            <ul>
              <li>
                Do not use a stripper  header, as it tends to  drop a lot of
                seed onto  the soil surface.
              </li>
              <li>
                Harvest infested fields last.  Make sure you do not  contaminate
                other fields  by moving equipment  from an affected field to a
                clean field.
              </li>
            </ul>
          </div>
          <div className="medium-3 columns border-right">
            <h4>Winter Management</h4>
            <ul>
              <li>
                If possible, burn straw  in affected field. Straw  should be cut
                as low as  possible to the ground to  facilitate burning.
              </li>
              <li>
                Do not perform fall tillage  as this may bury weedy  rice seed.
              </li>
              <li>Flood infested fields for as long as possible.</li>
            </ul>
          </div>
          <div className="medium-3 columns">
            <h4>Long Term Management</h4>
            <ul>
              <li>
                Fallow infested basins or fields. Apply flush irrigation in the
                spring and summer to geminate weedy rice seeds. Use a burn down
                herbicide or tillage to kill emerged plants.
              </li>
              <li>
                Crop rotation, if possible,  allows for the use of herbicide
                modes of action which are not available in rice.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  )
}
