import React from 'react'
import { withPrefix } from 'gatsby'
import PrevIssues from '../PrevIssues'

export default function Publications() {
  return (
    <div className="publications">
      <div className="row">
        <div className="small-12 columns">
          <h2>Publications</h2>
        </div>

        <div className="medium-5 large-7 columns">
          <h3>Storymap</h3>
          <ul>
            <li>
              <a
                href="https://arcg.is/0eD1Xi"
                target="_blank"
                rel="noopener noreferrer"
              >
                Identifying and Reporting Weedy Rice
              </a>
            </li>
          </ul>
          <h3>Handouts</h3>
          <ul>
            <li>
              <a
                href={withPrefix('downloads/weedy_rice_brochure.pdf')}
                target="_blank"
                rel="noopener noreferrer"
              >
                UCCE Weedy Rice Identification Guide
              </a>
            </li>
            <li>
              <a
                href={withPrefix(
                  'downloads/weedy-rice-bmps-2023.pdf'
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                Best Management Practices for Weedy Rice
              </a>
            </li>
            <li>
              <a
                href={withPrefix(
                  'downloads/california-weedy-rice-sample-protocol-2023.pdf'
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                California Weedy Rice Sample Protocol 2023
              </a>
            </li>
          </ul>

          <h3>Presentations</h3>
          <ul>
            <li>
              <a
                href={withPrefix(
                  'downloads/weedy-rice-survey-2021.pdf'
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                2021 Weedy Rice Survey
              </a>
            </li>
            <li>
              <a
                href={withPrefix(
                  'downloads/2019-Weedy-Rice-Workshop-Luis-Espino.pdf'
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                2019 Weedy Rice Workshop: Luis Espino, UCCE
              </a>
            </li>
            <li>
              <a
                href={withPrefix(
                  'downloads/2019-WeedyRice-Workshop–Research-Update.pdf'
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                2019 Weedy Rice Workshop – Research Update: Whitney Brim-
                DeForest, UCCE
              </a>
            </li>
            <li>
              <a
                href={withPrefix(
                  'downloads/2019-Weedy-Rice-Workshp–Seed-Certification-and-Quality-Assurance-Programs.pdf'
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                2019 Weedy Rice Workshop – Seed Certification and Quality
                Assurance Programs: Timothy Blank, Crop Improvement
              </a>
            </li>
            <li>
              <a
                href={withPrefix('downloads/2019-Rice-Grower-Meetings.pdf')}
                target="_blank"
                rel="noopener noreferrer"
              >
                2019 Rice Grower Meetings: Whitney Brim-DeForest, UCCE
              </a>
            </li>
            <li>
              <a
                href={withPrefix('downloads/2018-Rice-Grower-Meetings.pdf')}
                target="_blank"
                rel="noopener noreferrer"
              >
                2018 Rice Grower Meetings: Whitney Brim-DeForest, UCCE
              </a>
            </li>
            <li>
              <a href="http://rice.ucanr.edu/files/255597.pdf" target="_blank">
                2017 Rice Grower Meetings: Whitney Brim-DeForest, UCCE
              </a>
            </li>
            <li>
              <a href="http://rice.ucanr.edu/files/229841.pdf" target="_blank">
                2016 Rice Grower Meetings: Timothy Blank, Crop Improvement
                Association
              </a>
            </li>
          </ul>

          <h3>Research Papers</h3>
          <ul>
            <li>
              <a
                href={withPrefix(
                  'downloads/2020-karn-et-al.pdf'
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                Effects of competition from California weedy rice (Oryza sativa f. spontanea) biotypes on a cultivated rice variety. 2020. Weed Technology
              </a>
            </li>
            <li>
              <a
                href={withPrefix(
                  'downloads/Genetic-variation-and-possible-origins-of-weedy-rice-found-in-California.pdf'
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                Genetic variation and possible origins of weedy rice found in
                California. 2019. Ecology and Evolution 2019: 1-14
              </a>
            </li>
          </ul>

        </div>
        <div className="medium-6 medium-offset-1 large-4 large-offset-1 columns">
          <div className="sidebox">
            <h3>Weedy Rice Update E-Newsletters</h3>
            <p>
              To receive the latest updates on the weedy rice situation in
              California, subscribe to our electronic newsletter Weedy  Rice
              Update
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://visitor.r20.constantcontact.com/d.jsp?llr=l5silwzab&p=oi&m=1127105186763&sit=pwl74dilb&f=10726d76-9702-43ef-bbc1-7e832b37948f"
              className="button large radius"
            >
              subscribe
            </a>
            <hr />
            <strong>Previous issues</strong>
            <PrevIssues />
          </div>
        </div>
      </div>
    </div>
  )
}
