import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Identification from '../components/Identification'
import Contact from '../components/Contact'
import Prevention from '../components/Prevention'
import Poblications from '../components/Publications'
import Sample from '../components/Sample'
import WhatWhy from '../components/WhatWhy'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <WhatWhy />
    <span className="anchor" id="identification" />
    <Identification />
    <span className="anchor" id="prevention" />
    <Prevention />
    <span className="anchor" id="sample" />
    <Sample />
    <span className="anchor" id="publications" />
    <Poblications />
    <span className="anchor" id="contact" />
    <Contact />
  </Layout>
)

export default IndexPage
