import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Type1 from '../../assets/img/type_1.jpg'
import Type2 from '../../assets/img/type_2.jpg'
import Type3 from '../../assets/img/type_3.jpg'
import Type4 from '../../assets/img/type_4.jpg'
import Type5 from '../../assets/img/type_5.jpg'
import Type6 from '../../assets/img/type_6.jpg'
import TypeModal from '../TypeModal'

const descrpt = [
  ['Awnless', 'Straw hull color', 'Tall stature', 'No color on nodes'],
  ['Awnless', 'Bronze hull color', 'Tall stature', 'No color on nodes'],
  ['Awned', 'Straw hull color', 'Tall stature', 'No color on nodes'],
  ['Awned', 'Black hull color', 'Short stature', 'No color on nodes'],
  ['Awnless', 'Straw hull color', 'Tall stature', 'Purple-colored nodes'],
  ['Awned', 'Black hull color', 'Tall stature', 'No color on nodes'],
]

export default function Identification() {
  return (
    <StaticQuery
      query={graphql`
        query {
          Type1: allFile(filter: { absolutePath: { regex: "/(type1)/" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          Type2: allFile(filter: { absolutePath: { regex: "/(type2)/" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          Type3: allFile(filter: { absolutePath: { regex: "/(type3)/" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          Type4: allFile(filter: { absolutePath: { regex: "/(type4)/" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          Type5: allFile(filter: { absolutePath: { regex: "/(type5)/" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          Type6: allFile(filter: { absolutePath: { regex: "/(type6)/" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <React.Fragment>
          <div className="identification">
            <div className="row">
              <h2>Identification</h2>
            </div>
            <div className="row">
              <div className="video-container">
                <iframe
                  width="853"
                  height="480"
                  src="https://www.youtube.com/embed/8CInJTKya2g?rel=0"
                  frameBorder="0"
                  allowFullScreen
                  title="Youtube video"
                />
              </div>
            </div>
            <div className="row">
              <div id="idBoxes">
                <div className="idBox instruction">
                  <p>
                    The following are the weedy rice types indentified in
                    California so far:
                  </p>
                  <p className="small">Click the images to learn more</p>
                </div>
                <TypeModal
                  data={data.Type1}
                  teaser={Type1}
                  label="Type 1"
                  description={descrpt[0]}
                />
                <TypeModal
                  data={data.Type2}
                  teaser={Type2}
                  label="Type 2"
                  description={descrpt[1]}
                />
                <TypeModal
                  data={data.Type3}
                  teaser={Type3}
                  label="Type 3"
                  description={descrpt[2]}
                />
                <TypeModal
                  data={data.Type4}
                  teaser={Type4}
                  label="Type 4"
                  description={descrpt[3]}
                />
                <TypeModal
                  data={data.Type5}
                  teaser={Type5}
                  label="Type 5"
                  description={descrpt[4]}
                />
                <TypeModal
                  data={data.Type6}
                  teaser={Type6}
                  label="Type 6"
                  description={descrpt[5]}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    />
  )
}
