import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import Slider from 'react-slick'
import BackgroundImage from 'gatsby-background-image'

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

class TypeModal extends React.Component {
  state = {
    isShown: false,
  }

  _toggleModal = () => {
    const { isShown } = this.state
    this.setState({ isShown: !isShown })
  }

  _handleOpen = src => () => {
    if (typeof window !== 'undefined') {
      const win = window.open(src, '_blank')
      win.focus()
    }
  }

  render() {
    const { teaser, data, label, description } = this.props
    const { isShown } = this.state
    return (
      <React.Fragment>
        <div className="idBox" onClick={this._toggleModal} role="button">
          <img src={teaser} alt="" />
          <div className="type">
            <p>{label}</p>
          </div>
        </div>

        <ReactModal
          isOpen={isShown}
          overlayClassName="gallery"
          className="galContent"
          shouldCloseOnOverlayClick={false}
          bodyOpenClassName="galActive"
          ariaHideApp={false}
          shouldFocusAfterRender={false}
        >
          <div className="close" onClick={this._toggleModal} role="button">
            <span />
          </div>

          <div className="galSlider">
            <Slider {...settings}>
              {data.edges.map(item => (
                <div
                  key={item.node.childImageSharp.fluid.src}
                  onClick={this._handleOpen(
                    item.node.childImageSharp.fluid.src
                  )}
                >
                  <BackgroundImage
                    className="galleryitem"
                    fluid={item.node.childImageSharp.fluid}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="info">
            <div className="infoType">
              <p>{label}</p>
            </div>
            <div className="infoList">
              <ul>{description && description.map(el => <li>{el}</li>)}</ul>
            </div>
          </div>
        </ReactModal>
      </React.Fragment>
    )
  }
}

TypeModal.propTypes = {
  teaser: PropTypes.string,
  data: PropTypes.object,
  label: PropTypes.string,
}

export default TypeModal
