import React from 'react'
import transportationIcon from '../../assets/img/detain-transportation-icon.png'
import contactIcon from '../../assets/img/contact-icon.png'
import submitterIcon from '../../assets/img/submitter-icon.png'
import googlePlay from '../../assets/img/google-play-badge.png'
import appStore from '../../assets/img/Download_on_the_App_Store_Badge_US-UK_135x40.svg'

export default function Sample() {
  return (
    <div className="sample">
      <div className="row">
        <div className="small-12 columns">
          <h2>Sample Collection</h2>
          <p>
            To minimize the movement of weedy rice seed, plant material and
            contaminated soil, the University of California, in coordination
            with the California Rice Research Board and California Rice
            Commission, will be implementing the following weedy rice sample
            collection protocol, effective 2017.
          </p>

          <p>
            PLEASE NOTE: AS OF 2017, NO SAMPLE SUBMISSIONS WILL BE ACCEPTED AT
            THE RICE EXPERIMENT STATION
          </p>

          <div className="collection-wrapper">
            <div className="collection-icon">
              <img src={transportationIcon} alt="" />
            </div>
            <div className="collection-detail">
              <h3 className="collection-heading">1) Detain Transportation</h3>
              <div className="collection-content">
                <p>
                  Suspected weedy rice seed, plant material and soil should not
                  be transported out of the possibly contaminated field.
                </p>
              </div>
            </div>
          </div>

          <div className="collection-wrapper">
            <div className="collection-icon">
              <img src={contactIcon} alt="" />
            </div>
            <div className="collection-detail">
              <h3 className="collection-heading">
                2) Contact UCCE Rice Farming Systems Advisors:
              </h3>
              <div className="collection-content">
                <p>
                  If a suspected plant is found, contact one of the UCCE Rice
                  Farming Systems Advisors:
                </p>
                <p>
                  <strong>Sacramento Valley:</strong>
                  <br />
                  Whitney Brim-DeForest (530-822-7515)
                  <br />
                  Luis Espino (530-635-6234)
                </p>
                <p>
                  <strong>San Joaquin Valley:</strong>
                  <br />
                  Michelle Leinfelder-Miles (209-953-6120)
                </p>
                <p>
                  The Advisor will visit the field, and if necessary, take a
                  leaf or seed sample, depending on the timing and maturity of
                  the plants. Growers and Pest Control Advisers do not have to
                  be present in the field for collection.
                </p>
              </div>
            </div>
          </div>

          <div className="collection-wrapper">
            <div className="collection-icon">
              <img src={submitterIcon} alt="" />
            </div>
            <div className="collection-detail">
              <h3 className="collection-heading">
                3) Submitter Recieves Status
              </h3>
              <div className="collection-content">
                <p>
                  The status of the sample (whether or not it is weedy rice)
                  will be given to the submitter as soon as identification is
                  confirmed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
